document.addEventListener('DOMContentLoaded', () => {
  const galleries = document.querySelectorAll('.wp-block-gallery-slider');

  galleries.forEach(gallery => {
    const swiperContainer = gallery.querySelector('.swiper-container');

    const swiper = new Swiper(swiperContainer, {
      slidesPerView: 'auto',
      spaceBetween: 0,
      freeMode: true,
      centeredSlides: false, // Ensure slides start from the left
      loop: false, // Keep false if you don't want looping
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {},
    });

    // Initialize: set first slide as active
    if (swiper.slides.length > 0) {
      swiper.slides[0].classList.add('swiper-slide-active');
    }
  });
});
